import React from 'react';
import logo from './logo.svg';

function App() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <img src={logo} alt="SiteScreen Logo" className="mt-5 mb-5" style={{width: "250px"}} />

      <div className="p-6 w-5/6 mx-auto md:w-1/3 bg-white rounded-lg border border-gray-200 shadow-md">
        <p className="text-gray-600 mb-4">
          SiteScreen helps protect businesses, customers and general Internet users from online scams and phishing attacks by verifying the authenticity of website pages and links.
        </p>
        <a href="https://forms.gle/qzPzkQYhGcMXPw71A" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-700 font-semibold">
          Sign up for Early Access
        </a>
      </div>
    </div>
  );
}

export default App;